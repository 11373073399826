<!--
    User Filter
    This component is used to filter the users.

    Visual:
    - The component is a dropdown with a button to open it
    - The dropdown contains a list of filters

    Props:
    - filter: The current filter (modelValue)

    Events:
    - update: Emitted when the filter is updated
 -->

<template>
  <div>
    <el-dropdown trigger="click" :hide-on-click="false">
      <!-- Button -->
      <SmButton type="primary" size="small" outline class="filter-button">
        <v-icon
          name="md-filteralt-round"
          class="filter-icon mr-2 fill-contrast" />
        <span> {{ i18n.t('filter') }} </span>
        <span v-if="numberOfFiltersSet > 0">{{ numberOfFiltersSet }}</span>
      </SmButton>

      <!-- Dropdown Content -->
      <template #dropdown>
        <div class="filter-wrapper">
          <!-- FilterSelects -->
          <span>{{ i18n.t('hideUsersWithoutApp') }}: </span>
          <SmCheckbox v-model:model-value="modelValue.hideUsersWithoutApp" />
          <span>{{ i18n.t('hideUsersWithApp') }}: </span>
          <SmCheckbox v-model:model-value="modelValue.hideUsersWithApp" />
          <!-- Include Deactivated licenses in app count -->
          <span>{{ i18n.t('includeDeactivatedLicenses') }}: </span>
          <SmCheckbox
            v-model:model-value="modelValue.includeDeactivatedLicenses" />
        </div>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import SmCheckbox from './sm/SmCheckbox.vue'

  export type UserFilterType = {
    hideUsersWithoutApp: boolean
    hideUsersWithApp: boolean
    includeDeactivatedLicenses: boolean
    tags: string[]
  }

  const i18n = useI18n()

  const numberOfFiltersSet = computed(() => {
    let number = 0
    Object.values(modelValue.value).forEach((value) => {
      if (value === true) number++
    })
    return number
  })

  const modelValue = defineModel('modelValue', {
    type: Object as PropType<UserFilterType>,
    default: () => {
      return {
        hideUsersWithoutApp: true,
        hideUsersWithApp: false,
        tags: [],
      }
    },
  })
</script>

<style scoped lang="scss">
  .filter-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-items: start;
    padding: 10px;
    gap: 10px;
  }
</style>
