<template>
  <SmDialog v-model:visibility="visibility" :title="i18n.t('export')" size="small">
    <template #default>
      <span> {{ i18n.t('exportSelectFormat.description') }} </span>
      <div class="format-selection">
        <SmCheckbox v-model="oneRowPerLicense" class="mb-3">
          {{ i18n.t('exportShowOneAccountPerLine') }}
        </SmCheckbox>

        <el-form>
          <el-form-item label="Format:">
            <el-radio-group v-model="format">
              <el-radio-button :label="OutputFormat.CSV" />
              <el-radio-button :label="OutputFormat.JSON" />
              <el-radio-button :label="OutputFormat.XLSX" />
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <template #footer>
      <div style="display: flex; gap: 1rem">
        <SmButton v-if="!loading" outline @click="visibility = false">
          {{ i18n.t('general.cancel') }}
        </SmButton>

        <SmButton :loading="loading" @click="exportData">
          {{ i18n.t('export') }}
        </SmButton>
      </div>
    </template>
  </SmDialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'

import { useI18n } from 'vue-i18n'

import { OutputFormat } from '@/client'
import { exportResource } from '@/common/export'
import { getApiErrorMessage } from '@/common/util/apiError'
import { sendToast } from '@/components/sm/SmNotification'

const i18n = useI18n()

const visibility = defineModel('visibility', {
  type: Boolean,
  default: true,
})

const loading = ref(false)

const format = ref<OutputFormat>(OutputFormat.CSV)

const props = defineProps<{ softwareId?: string }>()

const oneRowPerLicense = ref<boolean>(false)

function exportData() {
  loading.value = true
  const queryParams: Parameters<typeof exportResource>[0]['queryParams'] = { "one_row_per_software_license": oneRowPerLicense.value }
  if (props.softwareId) queryParams['softwareId'] = props.softwareId
  exportResource({
    requestBody: { output_format: format.value, one_row_per_software_license: oneRowPerLicense.value },

    queryParams: queryParams,
  })
    .then(
      async (response) => {
        if (response.status !== 200) {
          loading.value = false
          return
        }
        let returnValue
        loading.value = false
        if (format.value === OutputFormat.JSON) {
          returnValue = JSON.stringify(await response.json(), null, 2)
        } else if (format.value == OutputFormat.XLSX) {
          returnValue = await response.blob()
        } else returnValue = await response.text()

        const blob = new Blob([returnValue], {
          type: 'text/plain',
        })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        // Use current date as filename for the export
        // Use dayjs to format the date
        const date = dayjs()

        link.setAttribute(
          'download',
          `${date.format(
            'YYYY-MM-DD'
          )}-licenses.${format.value.toLowerCase()}`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
        visibility.value = false
        sendToast(
          i18n.t('licensesExportedSuccessfully'),
          undefined,
          'success'
        )
      },
      (error) => {
        sendToast(
          getApiErrorMessage(error, i18n as ReturnType<typeof useI18n>),
          undefined,
          'error'
        )
      }
    )
    .finally(() => {
      loading.value = false
    })
}
</script>

<style scoped>
.format-selection {
  margin-top: 1rem;
}
</style>
