<template>
  <SmDialog
    v-model:visibility="visibility"
    :title="i18n.t('tagManage')"
    width="medium">
    <!-- Delete Dialog -->
    <DeleteConfirmDialog
      v-model:visible="deleteConfirmDialogVisible"
      :title="i18n.t('deleteTag')"
      :sub-title="i18n.t('deleteTagText', { name: deleteTag?.name })"
      @cancel="deleteConfirmDialogVisible = false"
      @delete="handleDeleteTagConfirm" />

    <!-- Edit Dialog -->
    <div>
      <TagCreateDialog
        v-model:visibility="editDialogVisible"
        :tag="editTag"
        :edit-mode="true"
        @saved="loadTags" />
    </div>

    <!-- Create Dialog -->
    <div>
      <TagCreateDialog
        v-model:visibility="createDialogVisible"
        @saved="loadTags" />
    </div>

    <!-- Header -->
    <template #close>
      <div style="display: flex; gap: 1rem; align-items: center">
        <SmButton @click="createDialogVisible = true">
          {{ i18n.t('newTag') }}
        </SmButton>
        <SmButtonClose @close="visibility = false" />
      </div>
    </template>

    <SmTable
      v-model:data="currentTags"
      :columns="columns"
      key-field="_id"
      draggable
      @row-drag="handleRowDrag">
      <!-- Tag -->
      <template #tag="{ row }">
        <TagComponent :tag="row" />
      </template>

      <!-- Action -->
      <template #action="{ row }">
        <SmDropdown>
          <SmDropdownItem icon="md-modeedit-round" @click="handleEditTag(row)">
            {{ i18n.t('edit') }}
          </SmDropdownItem>

          <SmDropdownItem icon="md-delete-round" @click="handleDeleteTag(row)">
            {{ i18n.t('delete') }}
          </SmDropdownItem>
        </SmDropdown>
      </template>
    </SmTable>
  </SmDialog>
</template>

<script setup lang="ts">
  import { useI18n } from 'vue-i18n'

  import { Tag, TagsService, PaginationSortOrder } from '@/client'

  import SmTable from './sm/SmTable.vue'
  import { Column } from './sm/SmTable.types'

  const i18n = useI18n()

  const currentTags = ref<Tag[]>([])

  const emit = defineEmits<{
    'tag-changed': []
  }>()

  const columns: Column<Tag>[] = [
    {
      label: 'Tag',
      key: 'tag',
      width: '150px',
      sortable: false,
    },
    {
      label: 'Beschreibung',
      key: 'description',
      width: 1,
      sortable: false,
    },
    {
      label: '',
      key: 'action',
      width: '40px',
      sortable: false,
    },
  ]

  const visibility = defineModel('visibility', {
    type: Boolean,
    default: false,
  })

  onMounted(async () => {
    await loadTags()
  })

  //   Delete
  const deleteConfirmDialogVisible = ref(false)
  const deleteTag = ref<Tag>()

  function handleDeleteTag(tag: Tag) {
    deleteTag.value = tag
    deleteConfirmDialogVisible.value = true
    emit('tag-changed')
  }

  function handleRowDrag() {
    emit('tag-changed')
  }

  async function loadTags() {
    currentTags.value = await TagsService.getTagsApiV1ManagementTagsGet({
      sortBy: 'sorting',
      sortOrder: PaginationSortOrder.DESC,
    })
    emit('tag-changed')
  }

  async function handleDeleteTagConfirm() {
    if (deleteTag.value && deleteTag.value._id) {
      await TagsService.deleteTagApiV1ManagementTagsTagIdDelete({
        tagId: deleteTag.value._id,
      })
      currentTags.value = currentTags.value.filter(
        (tag) => tag._id !== deleteTag.value?._id
      )
    }
    deleteConfirmDialogVisible.value = false
    emit('tag-changed')
  }

  //   Edit
  const editDialogVisible = ref(false)
  const editTag = ref<Tag>()

  function handleEditTag(tag: Tag) {
    editTag.value = tag
    editDialogVisible.value = true
  }

  // Create
  const createDialogVisible = ref(false)
</script>
