import { LicenceExportConfig } from '@/client'
import { OpenAPI } from '@/client/core/OpenAPI'
import handleError from '@/middlewares/handleError'

export async function exportResource({
  requestBody,
  queryParams,
}: {
    requestBody: LicenceExportConfig
    queryParams: {
      softwareId?: string
    }
  }) {
  const headers = new Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${OpenAPI.TOKEN}`)
  const body: BodyInit = JSON.stringify(requestBody)

  const query: Record<string, string> = {}

  if (queryParams.softwareId) query['software_id'] = queryParams.softwareId

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: headers,
    body: body,
  }

  return fetch(
    `${OpenAPI.BASE}/api/v1/export/licences?${new URLSearchParams(query)}`,
    requestOptions
  ).then(handleError)
}
