<!-- depreacted by actions PR -->
<template>
  <div>
    <!-- <div class="task">
      <div>
        <h3>{{ task.title }}</h3>
      </div>
      <p>{{ task.description }}</p>
    </div> -->
  </div>
</template>

<!-- <script setup lang="ts">
  import { Task } from '@/types/tasks'

  const props = defineProps({
    task: {
      type: Object as PropType<Task>,
      required: true,
    },
  })
</script>

<style lang="scss">
  p {
    margin: 0;
  }

  .task {
    // Inherit from parent
  }
</style> -->
